import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import Blog from '../Components/Blog/Blog'
import BlogPage from '../Components/BlogPage/BlogPage'
import SiteMap from '../Tools/SiteMap/SiteMap'
import NotFound from '../Tools/NotFound/NotFound'
import Faq from '../Components/Faq/Faq'
import FaqPage from '../Components/FaqPage/FaqPage'
import Thanks from '../Tools/Thanks/Thanks'
import BlogEditor from '../Components/BlogEditor/BlogEditor'
import ServicesPageServicesPage from '../Components/ServicesPage/ServicesPage'
import BookAnAppointment from '../Components/BookAnAppointment/BookAnAppointment'
import ReviewsCust from '../Components/ReviewsCust/ReviewsCust'
import ServicesAll from '../Components/ServicesAll/ServicesAll'


export default function Main({ navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            <Route path="/" element={<HomePage navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/services" element={<ServicesAll />} />
            <Route path="/service/:service" element={<ServicesPageServicesPage navigateTo={navigateTo} Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog" element={<Blog Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blog/:id" element={<BlogPage Blogs={Blogs} FaqList={FaqList} />} />
            <Route path="/blogeditor" element={<BlogEditor />} />
            <Route path="/faq" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />
            <Route path="/faq/:id" element={<FaqPage FaqList={FaqList} Blogs={Blogs} />} />
     
            {/*  */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/reviews" element={<ReviewsCust />} />
            <Route path="/book" element={<BookAnAppointment />} />
            <Route path="/thanks" element={<Thanks navigateTo={navigateTo} />} />
            {/* <Route path="/about" element={<About  />} /> */}
            {/* <Route path="/support" element={<Support  navigateTo={navigateTo} />} /> */}


            {/* SiteMap */}
            {["/sitemaps", "/sitemap", "/widgets"].map((path, index) =>
                <Route path={path} key={index} element={<SiteMap Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            )}
            <Route path="/sitemap/:id" element={<SiteMap Blogs={Blogs} FaqList={FaqList} WebSitePages={WebSitePages} />} />
            {/* 404 */}
            <Route path="*" element={<NotFound />} />

            {/* Emails */}
            <Route path="/emails" element={<Faq FaqList={FaqList} Blogs={Blogs} />} />

        </Routes>
        // </div>
    )
}
